import WhiteLogo from '../Logo_bianco.png';
import GreenLogo from '../Logo_verde.png'
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

export const Navigation = (props) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen((prevIsNavbarOpen) => !prevIsNavbarOpen);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isNavbarOpen && !event.target.closest('.navbar')) {
        closeNavbar();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isNavbarOpen]);

  return (
<nav id='menu' className={`navbar navbar-default navbar-fixed-top${isNavbarOpen ? ' open' : ''}${isNavbarOpen ? ' show' : ''}`}>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className={`navbar-toggle${isNavbarOpen ? ' active' : ''} collapsed`}
            onClick={toggleNavbar}
          >
            <span className='sr-only'>Toggle navigation</span>
            <img src={isNavbarOpen ? GreenLogo : WhiteLogo} className='logo' alt='Logo' />
          </button>
          <HashLink to='/#page-top'>
            <h1 className='navbar-brand page-scroll'>Emilio Gambone</h1>
          </HashLink>
        </div>

        <div className={`collapse navbar-collapse${isNavbarOpen ? ' show' : ''}`} id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <HashLink to='/#about' className='page-scroll' onClick={closeNavbar}>
                About
              </HashLink>
            </li>
            <li>
              <HashLink to='/#features' className='page-scroll' onClick={closeNavbar}>
                Tech
              </HashLink>
            </li>
            <li>
              <HashLink to='/#portfolio' className='page-scroll' onClick={closeNavbar}>
                Portfolio
              </HashLink>
            </li>
            <li>
              <HashLink to='/blog' className='page-scroll' onClick={closeNavbar}>
                Blog
              </HashLink>
            </li>
            <li>
              <HashLink to='/#services' className='page-scroll' onClick={closeNavbar}>
                Get in touch
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
