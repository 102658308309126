import React from 'react';
import {About} from './about'; // Adjust the path as needed
import {Features} from './features'; // Adjust the path as needed
import {CustomGallery} from './gallery'; // Adjust the path as needed
import {Services} from './services';
import { Link } from 'react-router-dom';

const Home = ({ landingPageData }) => (
  <div>
    <About data={landingPageData.About} />
    <Features data={landingPageData.Features} />
    <CustomGallery />
    <div style={{ textAlign: 'center', margin: '50px' }}>
      <Link to="/blog">
        <h2 className='text-centered'>Go to Blog</h2>
      </Link>
    </div>
    <Services data={landingPageData.Services} />
    
    {/* Add a link to the Blog page */}
    {/* <Link to="/blog">Go to Blog</Link> */}
  </div>
);

export default Home;