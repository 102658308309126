export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          {/* <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
          </div> */}
          <div className='col-xs-12 col-md-12'>
            <div className='about-text'>
            <h2 className='text-center'>About Me</h2>
            {props.data ? (
                <p>
                  {props.data.paragraph.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              ) : (
                'loading...'
              )}
              <h3>I have interest about: </h3>
              <div className='list-style'>
                <div className='col-lg-12 col-sm-12 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                {/* <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
