const galleryData = [
    { thumb: "img/portfolio/backend.drawio.svg", diagram: "img/portfolio/backend.drawio.svg", title: "Backend Decoupling Proof of concept",
    description: (
      <>
        Decoupling ensures modular, maintainable, and scalable software architectures
        <br></br>
        <strong>Controllers:</strong>  maintain a clean separation by solely managing the interface between clients and applications.
        <br></br>
        <strong>Services:</strong> encapsulate business logic to promote code reuse.
        <br></br>
        <strong>Repositories:</strong> abstract data storage details.
      </>
    ),
    },
    { thumb: "img/portfolio/rateLimiter.drawio.svg", diagram: "img/portfolio/backend.drawio.svg", title: "Rate Limiter Architecture",
    description: (
      <>
        Simple API Rate Limiter Architecure.
        <br></br>
        <strong>Benefits:</strong> to prevent DoS, reduce costs and prevent servers overloading.
        <br></br>
        <strong>Requirements:</strong> High fault tolerance, exception handling, use as little memory as possible.
        <br></br>
        <strong>Flexibility: </strong> to support limiting based on different factors (IP, API key...) and different limiters algorithms (leaking bucket, token bucket...)
        <br></br>
        <strong>Functioning:</strong> the counters are stored in Redis when limit is reached, different options are available: (drop request, return 429 code, message queue...).
        <br></br>
        <strong>Distributed system: </strong> in large distributed systems would be a challenge to lock and sync without slow down the system significally.
      </>
    ),
    },
    { thumb: "img/portfolio/greenhouse.drawio.svg", diagram: "img/portfolio/greenhouse.drawio.svg", title: "IoT Greenhouse Design", 
      description: (
        <>
          Architecture of an IoT system (Aquaponic System) i partecipate designing.
          <br></br>
          <strong>Sensors </strong>transmit data via Arduino.
          <br></br>
          <strong>Data processing </strong> and storage in InfluxDB for time-series visualization
          <br></br>
          <strong>Front End: </strong> real-time and historical data visualization and actuators triggering via Raspberry.
          <br></br>
          <strong>Thresold rules </strong> established to automate actuators trigger and send notifications when exceeded.
        </>
      )    
  },
    { thumb: "img/portfolio/decision-making.png", diagram: "img/portfolio/decision-making.png", title: "Decision Making",
    description: (
      <>
      Decision Making (From real world experience)
      <br></br>
      Q: Management is proposing a switch in SaaS software providers. In addition to considering the product cost, what evaluations would you conduct before transitioning to the new supplier?
      <br></br>
      <br></br>
      A: <strong>1. Evaluate</strong> the supplier company (benchmark their tools, assess their years in operation, workforce size, and industry specialization).
      <br></br>
        <strong>2. Assess</strong> the costs associated with system integration, data migration, and training employees for the new provider.
        <br></br>
        <strong>3. the list is never ending...</strong>
      </>
    )
  },
    // { thumb: "img/portfolio/extreme.png", title: "Lorem Ipsum5" },
    // { thumb: "img/portfolio/cloud.jpeg", title: "Lorem Ipsum6" },
    // { thumb: "img/portfolio/devops.png", title: "Lorem Ipsum7" },
    // { thumb: "img/portfolio/smarthome.png", title: "Lorem Ipsum8" },
    // { thumb: "img/portfolio/leadership.jpg", title: "Lorem Ipsum9" }
  ];
  
  export default galleryData;
  