import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom';
import { Navigation } from './components/navigation'
import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import  Home  from './components/home'
import Blog  from './components/blog'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
  //   <div>
  //     <Navigation />
  //     {/* <Header data={landingPageData.Header} /> */}
  //     <About data={landingPageData.About} />
  //     <Features data={landingPageData.Features} />
  //     <Gallery />
  //     <Services data={landingPageData.Services} />
  //     {/* <Testimonials data={landingPageData.Testimonials} /> */}
  //     {/* <Routes>
  //     <Route path="/blog" element={<Blog />} />
  //     </Routes> */}
  //     {/* <Footer /> */}
  //   </div>
  // )

    <Router>
      <div>
        <Navigation />
        
        <Routes>
          <Route path="/" element={<Home landingPageData={landingPageData} />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App
