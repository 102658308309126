// CustomGallery.js

import React from 'react';
import MyGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import galleryData from '../data/galleryData';

export const CustomGallery = (props) => {
  const data = galleryData;
  const images = data.map((obj) => ({
    original: obj.thumb.replace('-small', '-large'),
    thumbnail: obj.thumb,
    description: obj.description,
    originalAlt: obj.title,
    thumbnailAlt: obj.title,
  }));

  const renderCustomItem = (item) => (
    <div className="image-gallery-item">
      <div className="image-gallery-image-container">
        <img
          src={item.original}
          alt={item.originalAlt}
          className="image-gallery-image"
          style={{
            maxWidth: '100%', // Set the maximum width to 100% of the container
            maxHeight: '400px', // Set the maximum height (adjust as needed)
            display: 'block', // Ensure the image is treated as a block element
          }}
        />
      </div>
      <div className="image-gallery-description">
        <h3>{item.originalAlt}</h3>
      </div>
    </div>
  );
  
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>To visually illustrate my comprehension, thought processes, and approach to challenges</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {/* Render the custom gallery with the custom render function */}
            <MyGallery items={images} renderItem={renderCustomItem} />
          </div>
        </div>
      </div>
    </div>
  );
};
