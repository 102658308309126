import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Blog = () => {
    const location = useLocation();

    useEffect(() => {
      // Scroll to the top when the component mounts or when the route changes
      window.scrollTo(0, 0);
    }, [location.pathname]);
  return (
    <div id='about'>
    <div className='container'>
      <h2>The Renaissance Man</h2>
      {/* Add your blog content here */}

      <blockquote><em>"The Renaissance Man is not confined by the boundaries of a single expertise but rather embraces the vast landscape of knowledge, painting a masterpiece with the colors of versatility, intellect, and curiosity."</em></blockquote>


      <p>The global trend is evident – achieving excellence in a narrow set of skills is the key to better jobs and salaries. Consider a specialized Database developer in optimization; it's lucrative now, but if AI takes over such tasks, relying solely on this expertise can lead to unemployment. This may sound extreme, but it illustrates the point.</p>
    
    <p>The underestimated risk is clear – without diversifying skills, the disappearance of the niche means no income. An alternative, not yet widely embraced, is broadening your skill set.</p>
    
    <p>Imagine a plumber who can also cook and has a solid understanding of accounting. This is what's known as a polymath – versatile skills that may result in a lower salary but make finding a new job less challenging.</p>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <img src="../../img//portfolio/polymath.drawio.svg" alt="Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
</div>    
    <p>So, why isn't this popular? Our consumption-focused society prioritizes earning more and spending on unnecessary things. However, adopting a polymath approach means learning new skills beneficial in daily life and aiding in saving money.</p>
    
    <p>Skills like understanding accounting, painting walls, DIY repairs, and cooking serve as a plan B, creating a cushion for unforeseen circumstances.</p>

    <p>Some skills I've learned in my life include:</p>
    <ul style={{ marginLeft:'30px', listStyleType: 'disc' }}>
  <li>Doing little electric works</li>
  <li>Repairing stuff</li>
  <li>Playing guitar</li>
  <li>Cooking</li>
</ul>

<p>Moreover, I've met inspiring individuals who have not only excelled in IT but have also embraced various skills outside their professional domain, demonstrating the beauty of a polymathic mindset.</p>


    </div>
    </div>
  );
};
export default Blog;