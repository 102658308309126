import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Get in touch</h2>
        </div>
        <div className='row'>
        <div className='container text-center'>
          <p>
            <a href='https://github.com/emiliogambone' target='_blank' rel='noopener noreferrer external'>
              <FontAwesomeIcon icon={faGithub} className='icon white'/>
            </a>
            <a href='https://www.linkedin.com/in/emilio-gambone-41624458/' target='_blank' rel='noopener noreferrer external'>
              <FontAwesomeIcon icon={faLinkedin} className='icon white'/>
            </a>
            <a href='mailto:emilio.gambone@gmail.com' target='_blank' rel='noopener noreferrer external'>
              <FontAwesomeIcon icon={faEnvelope} className='icon white'/>
            </a>
          </p>
        </div>
        </div>
      </div>
    </div>
  )
}
